.header {
  width: 100%;
  height: 5rem;
  background: rgb(173,226,237);
  background: linear-gradient(90deg, rgba(173,226,237,1) 0%, rgba(235,222,198,1) 72%, rgba(230,201,168,1) 100%, rgba(0,212,255,1) 100%);
  padding: 0 10%;
  border: .25rem solid;
  border-radius: .5rem;
  border-color: rgb(18, 88, 100);
}

.header nav {
  height: 100%;
  border-bottom: 5rem;
}

.header ul {
  height: 100%;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.header li {
  margin: 0 1rem;
  width: 5rem;
  font-family: sans-serif;
}

.header a {
  color: black;
  text-decoration: none;
}

.header a:hover,
.header a:active,
.header a.active {
  color: #122338;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid #122338;
}